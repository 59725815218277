.MenuLinksList {

    h1 {
        font-size: var(--font-size-normal);
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
        border-bottom: 1px solid hsl(0 0% 40%);
        color: var(--colour-text-accent-secondary);
        &:empty { display: none; }
    }
    
    ul {
        padding: 0;
        column-width: 18em;
        column-gap: 4em;
        li {
            list-style-type: none;
            line-height: 1.6em;
            > div > section { display: none; }
            > a,
            > button {
                height: 100%;
                background: transparent;
            }
        }
        a {
            color: var(--colour-text-accent-primary);
            white-space: nowrap;
            &:hover { color: var(--colour-text-accent-secondary); }
        }
    }

}